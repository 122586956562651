import React from 'react'
import { IconsProps } from 'msp-integrations'

const PlayIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 17 19'
    >
      <path d="m1.445.863 14.062 8.119-14.15 8.299.09-16.418Z" stroke="#FFF" fill="none" />
    </svg>
  )
}

export default PlayIcon
